@media only screen and (max-width: 600px) {
  .event-detail-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .event-detail-main-container h2 {
    
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1.056px;
    color: var(--typo-HEADING, rgba(0, 0, 0, 0.85));
  }

  .date-writer-layout {
    display: flex;
    gap: 32px;
    align-items: flex-start;
    align-self: stretch;
  }

  .date-layout {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  .date-layout p {
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    color: var(--typo-text-secondary, rgba(0, 0, 0, 0.45));
  }

  .date-layout img {
    width: 24px;
    height: 24px;
  }

  .writter-layout {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  .writter-layout p {
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    color: var(--typo-text-secondary, rgba(0, 0, 0, 0.45));
  }

  .writter-layout img {
    width: 24px;
    height: 24px;
  }

  .event-image-main-layout {
    height: 190.963px;
    width: 358px;
    border-radius: 8px;
    margin-top: 24px;
    object-fit: contain;
    object-position: center;
  }

  .isi-desc-event {
    margin-top: 24px;
    text-align: left;
  }

  .detail-selengkapnya-event-layout {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .detail-selengkapnya-event-button-layout {
    background-color: #09588d;
    display: flex;
    width: 200px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
  }

  .detail-selengkapnya-event-button-layout img {
    width: 24px;
    height: 24px;
  }

  .detail-selengkapnya-event-button-layout p {
    color: #fff;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    margin-top: 20px;
  }

  .berita-lainnya-gallery {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 22px;
    justify-content: center;
  }

  .berita-lainnya-layout {
    margin-top: 48px;
    margin-bottom: 240px;
  }

  .berita-lainnya-layout h2 {
    color: var(--typo-HEADING, rgba(0, 0, 0, 0.85));
    /* Heading/2 */
    
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.792px;
  }

  .container-event {
    margin-left: 10%;
    margin-right: 10%;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .event-detail-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .event-detail-main-container h2 {
    
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1.056px;
    color: var(--typo-HEADING, rgba(0, 0, 0, 0.85));
  }

  .date-writer-layout {
    display: flex;
    gap: 32px;
    align-items: flex-start;
    align-self: stretch;
  }

  .date-layout {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  .date-layout p {
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    color: var(--typo-text-secondary, rgba(0, 0, 0, 0.45));
  }

  .date-layout img {
    width: 24px;
    height: 24px;
  }

  .writter-layout {
    display: flex;
    flex-direction: row;
  }

  .writter-layout p {
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    color: var(--typo-text-secondary, rgba(0, 0, 0, 0.45));
  }

  .writter-layout img {
    width: 24px;
    height: 24px;
  }

  .event-image-main-layout {
    height: 290.963px;
    width: 658px;
    border-radius: 8px;
    margin-top: 24px;
  }

  .isi-desc-event {
    margin-top: 24px;
    text-align: left;
  }

  .detail-selengkapnya-event-layout {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .detail-selengkapnya-event-button-layout {
    background-color: #09588d;
    display: flex;
    width: 200px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
  }

  .detail-selengkapnya-event-button-layout img {
    width: 24px;
    height: 24px;
  }

  .detail-selengkapnya-event-button-layout p {
    color: #fff;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    margin-top: 20px;
  }

  .berita-lainnya-gallery {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 22px;
    width: 670px;
  }

  .berita-lainnya-layout {
    margin-top: 48px;
    margin-bottom: 240px;
  }

  .berita-lainnya-layout h2 {
    color: var(--typo-HEADING, rgba(0, 0, 0, 0.85));
    /* Heading/2 */
    
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.792px;
  }

  .container-event {
    margin-left: 9%;
    margin-right: 9%;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1920px) {
  .event-detail-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .event-detail-main-container h2 {
    
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1.056px;
    color: var(--typo-HEADING, rgba(0, 0, 0, 0.85));
  }

  .date-writer-layout {
    display: flex;
    gap: 32px;
    align-items: flex-start;
    align-self: stretch;
  }

  .date-layout {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  .date-layout p {
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    color: var(--typo-text-secondary, rgba(0, 0, 0, 0.45));
  }

  .date-layout img {
    width: 24px;
    height: 24px;
  }

  .writter-layout {
    display: flex;
    flex-direction: row;
  }

  .writter-layout p {
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    color: var(--typo-text-secondary, rgba(0, 0, 0, 0.45));
  }

  .writter-layout img {
    width: 24px;
    height: 24px;
  }

  .event-image-main-layout {
    height: 590.963px;
    width: 1200px;
    border-radius: 8px;
    margin-top: 24px;
  }

  .isi-desc-event {
    margin-top: 24px;
    text-align: left;
  }

  .detail-selengkapnya-event-layout {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .detail-selengkapnya-event-button-layout {
    background-color: #09588d;
    display: flex;
    width: 200px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
  }

  .detail-selengkapnya-event-button-layout img {
    width: 24px;
    height: 24px;
  }

  .detail-selengkapnya-event-button-layout p {
    color: #fff;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    margin-top: 20px;
  }

  .berita-lainnya-gallery {
    display: flex;
    align-items: flex-start;

    /* flex-wrap: wrap; */
    gap: 24px;
    margin-top: 22px;
    width: 1200px;
  }

  .berita-lainnya-layout {
    margin-top: 48px;
    margin-bottom: 240px;
  }

  .berita-lainnya-layout h2 {
    color: var(--typo-HEADING, rgba(0, 0, 0, 0.85));
    /* Heading/2 */
    
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.792px;
  }

  .container-event {
    margin-left: 8%;
    margin-right: 8%;
  }
}

@media only screen and (min-width: 1920px) {
  .event-detail-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .event-detail-main-container h2 {
    
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1.056px;
    color: var(--typo-HEADING, rgba(0, 0, 0, 0.85));
  }

  .date-writer-layout {
    display: flex;
    gap: 32px;
    align-items: flex-start;
    align-self: stretch;
  }

  .date-layout {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  .date-layout p {
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    color: var(--typo-text-secondary, rgba(0, 0, 0, 0.45));
  }

  .date-layout img {
    width: 24px;
    height: 24px;
  }

  .writter-layout {
    display: flex;
    flex-direction: row;
  }

  .writter-layout p {
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    color: var(--typo-text-secondary, rgba(0, 0, 0, 0.45));
  }

  .writter-layout img {
    width: 24px;
    height: 24px;
  }

  .event-image-main-layout {
    height: 790.963px;
    width: 1800px;
    border-radius: 8px;
    margin-top: 24px;
  }

  .isi-desc-event {
    margin-top: 24px;
    
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    text-align: left;
  }

  .detail-selengkapnya-event-layout {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .detail-selengkapnya-event-layout p {
    
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
  }

  .detail-selengkapnya-event-button-layout {
    background-color: #09588d;
    display: flex;
    width: 300px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
  }

  .detail-selengkapnya-event-button-layout img {
    width: 30px;
    height: 30px;
  }

  .detail-selengkapnya-event-button-layout p {
    color: #fff;
    
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.176px;
    margin-top: 20px;
  }

  .berita-lainnya-gallery {
    display: flex;
    margin-left: 8%;

    /* flex-wrap: wrap; */
    gap: 24px;
    margin-top: 22px;
    width: 1700px;
  }

  .berita-lainnya-layout {
    margin-top: 48px;
    margin-bottom: 240px;
  }

  .berita-lainnya-layout h2 {
    color: var(--typo-HEADING, rgba(0, 0, 0, 0.85));
    /* Heading/2 */
    
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.792px;
  }

  .container-event {
    margin-left: 10%;
    margin-right: 10%;
  }
}

.event-detail-main-container-pdf {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.event-detail-main-container-pdf h2 {
  
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.056px;
  color: var(--typo-HEADING, rgba(0, 0, 0, 0.85));
}

.date-writer-layout-pdf {
  display: flex;
  gap: 32px;
  align-items: flex-start;
  align-self: stretch;
}

.date-layout-pdf {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.date-layout-pdf p {
  
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.176px;
  color: var(--typo-text-secondary, rgba(0, 0, 0, 0.45));
}

.date-layout-pdf img {
  width: 24px;
  height: 24px;
}

.writter-layout-pdf {
  display: flex;
  flex-direction: row;
}

.writter-layout-pdf p {
  
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.176px;
  color: var(--typo-text-secondary, rgba(0, 0, 0, 0.45));
}

.writter-layout-pdf img {
  width: 24px;
  height: 24px;
}

.event-image-main-layout-pdf {
  height: 790.963px;
  width: 1800px;
  border-radius: 8px;
  margin-top: 24px;
}

.isi-desc-event-pdf {
  margin-top: 45px;
  
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.176px;
}

/* style download icon event */
/* mobile */
@media only screen and (max-width: 600px) {
  .container-download-event {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;
  }

  .line-proyek1-event {
    position: absolute;
    transform: translateY(-50%); /* Pusatkan garis di tengah secara vertikal */
    width: 90px; /* Lebar garis */
    height: 5px; /* Tinggi garis */
    left: 215px;
    background-color: #ebebeb; /* Warna garis */
  }
  .line-proyek2-event {
    position: absolute;
    transform: translateY(-50%); /* Pusatkan garis di tengah secara vertikal */
    width: 120px; /* Lebar garis */
    height: 5px; /* Tinggi garis */
    left: 0px;
    background-color: #ebebeb; /* Warna garis */
  }

  .container-download-event img {
    position: relative;
    z-index: 1; /* Letakkan gambar di atas garis */
    width: 80px;
  }
}

/* Tablet */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .container-download-event {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;
  }

  .line-proyek1-event {
    position: absolute;
    transform: translateY(-50%); /* Pusatkan garis di tengah secara vertikal */
    width: 200px; /* Lebar garis */
    height: 5px; /* Tinggi garis */
    right: 90px;
    background-color: #ebebeb; /* Warna garis */
  }
  .line-proyek2-event {
    position: absolute;
    transform: translateY(-50%); /* Pusatkan garis di tengah secara vertikal */
    width: 200px; /* Lebar garis */
    height: 5px; /* Tinggi garis */
    left: 90px;
    background-color: #ebebeb; /* Warna garis */
  }

  .container-download-event img {
    position: relative;
    z-index: 1; /* Letakkan gambar di atas garis */
    width: 100px;
  }
}

/* Laptop */
@media only screen and (min-width: 1366px) and (max-width: 1920px) {
  .container-download-event {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;
  }

  .line-proyek1-event {
    position: absolute;
    transform: translateY(-50%); /* Pusatkan garis di tengah secara vertikal */
    width: 300px; /* Lebar garis */
    height: 5px; /* Tinggi garis */
    right: 160px;
    background-color: #ebebeb; /* Warna garis */
  }
  .line-proyek2-event {
    position: absolute;
    transform: translateY(-50%); /* Pusatkan garis di tengah secara vertikal */
    width: 300px; /* Lebar garis */
    height: 5px; /* Tinggi garis */
    left: 160px;
    background-color: #ebebeb; /* Warna garis */
  }

  .container-download-event img {
    position: relative;
    z-index: 1; /* Letakkan gambar di atas garis */
    width: 150px;
  }
}

/* Desktop PC */
@media only screen and (min-width: 1920px) {
  .container-download-event {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;
  }

  .line-proyek1-event {
    position: absolute;
    transform: translateY(-50%); /* Pusatkan garis di tengah secara vertikal */
    width: 700px; /* Lebar garis */
    height: 5px; /* Tinggi garis */
    left: 850px;
    background-color: #ebebeb; /* Warna garis */
  }
  .line-proyek2-event {
    position: absolute;
    transform: translateY(-50%); /* Pusatkan garis di tengah secara vertikal */
    width: 660px; /* Lebar garis */
    height: 5px; /* Tinggi garis */
    left: 0px;
    background-color: #ebebeb; /* Warna garis */
  }

  .container-download-event img {
    position: relative;
    z-index: 1; /* Letakkan gambar di atas garis */
    width: 150px;
  }
}
