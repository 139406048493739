/* Style Buat Component Management Tittle */

/* Untuk Mobile */
@media only screen and (max-width: 600px) {
  .horizontal-line {
    display: none;
    opacity: 0;
  }
}

/* Untuk Tablet */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .horizontal-line {
    display: none;
    opacity: 0;
  }
}

/* Untuk Laptop */
@media only screen and (min-width: 1366px) and (max-width: 1920px) {
  .horizontal-line {
    border-top: 2px solid #ebebeb;
    width: 100%;
    top: -10px;
    position: relative;
  }
}

/* Untuk Desktop PC */
@media only screen and (min-width: 1920px) {
  .horizontal-line {
    border-top: 2px solid #ebebeb;
    width: 100%;
    top: -10px;
    position: relative;
  }
}

/* End Style Buat Component Management Tittle*/

/* Start Style Buat Component Triple Button */
/* Untuk Mobile */
@media only screen and (max-width: 600px) {
  .triple-button-container {
    display: flex;
    gap: 15px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    font-family: "Hedvig Letters Serif, serif";
  }

  .triple-button-container button {
    border-radius: 12px;
    padding: 8px 20px 8px 20px;
    color: #000;
    background-color: #ebebeb;
    font-size: 14px;
    font-family: "Hedvig Letters Serif, serif";
  }

  .triple-button-container button.selected {
    border-radius: 12px;
    padding: 8px 20px 8px 20px;
    color: #fff;
    background-color: #034da2;
    font-size: 14px;
    font-family: "Hedvig Letters Serif, serif";
  }
}

/* Untuk Tablet */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .triple-button-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin-top: 19px;
    font-family: "Hedvig Letters Serif, serif";
  }

  .triple-button-container button {
    border-radius: 20px;
    background: #ededed;
    padding: 6px 21px 6px 21px;
    color: #000;

    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-family: "Hedvig Letters Serif, serif";
  }

  .triple-button-container button.selected {
    border-radius: 20px;
    background: #faa61a;
    padding: 6px 21px 6px 21px;
    color: #000;

    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-family: "Hedvig Letters Serif, serif";
  }
}

/* Untuk Laptop */
@media only screen and (min-width: 1366px) and (max-width: 1440px) {
  .triple-button-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
    border-bottom-width: 4px;
  }

  .triple-button-container button {
    border-radius: 20px;
    background: #ededed;
    padding: 6px 21px 6px 21px;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-family: "Hedvig Letters Serif, serif";
  }

  .triple-button-container button.selected {
    border-radius: 20px;
    font-weight: bold;
    background-color: #3b82f6; /* focus:bg-blue-500 */
    color: #ffffff; /* focus:text-white */
    padding: 6px 21px 6px 21px;

    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-family: "Hedvig Letters Serif, serif";
  }

  .triple-button-container button:hover {
    border-radius: 20px;
    font-weight: bold;
    padding: 6px 21px 6px 21px;
    background-color: #3b82f6; /* hover:bg-blue-500 */
    color: #ffffff; /* hover:text-white */
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-family: "Hedvig Letters Serif, serif";
  }
}

/* Untuk Desktop PC */
@media only screen and (min-width: 1920px) {
  .triple-button-container {
    display: flex;
    flex-direction: column;
    margin-left: 104px;
    align-items: flex-start;
    margin-top: 90px;
    gap: 12px;
  }

  .triple-button-container button {
    padding: 6px 21px 6px 21px;
    color: #000;
    font-size: 16px;
    line-height: normal;
    font-family: "Maven Pro";
    width: 186px;
    text-align: left;
    border-bottom-width: 4px;
    border-bottom-color: #ededed;
  }

  .triple-button-container button.selected {
    padding: 6px 21px 6px 21px;
    font-weight: bold;
    background-color: #3b82f6; /* focus:bg-blue-500 */
    color: #ffffff; /* focus:text-white */
    font-size: 16px;
    line-height: 22px;
    font-family: "Maven Pro";
    width: 186px;
  }
  .triple-button-container button:hover {
    padding: 6px 21px 6px 21px;
    font-weight: bold;
    background-color: #3b82f6; /* hover:bg-blue-500 */
    color: #ffffff; /* hover:text-white */
    font-size: 16px;
    line-height: normal;
    font-family: "Maven Pro";
    width: 186px;
  }
}

/* End Of Style Buat Component Triple Button */

/* Style Component Top Section Pict */
/* Untuk Mobile */
@media only screen and (max-width: 600px) {
  .managament-layout-main {
    display: flex;
    flex-direction: column;
  }
  .top-section-pict-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    margin-left: 70px;
    margin-top: 30px;
  }

  .pict-list-management-wrapper {
    width: 290px;
    flex-shrink: 0;

    display: flex;
    flex-direction: column;
  }

  .management-text-wrapper {
    margin-top: 30px;
  }

  .overlay-text-management-tittle {
    color: #000;
    font-size: 22px;
    font-family: "Hedvig Letters Serif, serif";
    line-height: normal;
    width: 250px;
    margin-top: 20px;
  }

  .overlay-text-management-role {
    color: #000;

    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .overlay-text-management-desc {
    max-width: 255px;
    color: #000;
    font-size: 15px;
    font-style: normal;
    line-height: 1.4;
    font-family: "Maven Pro";
  }

  .management-image-wrapper {
    top: 20px;
    right: 35px;
    left: 0px;
    position: relative;
  }

  .management-image-wrapper img {
    width: 250px;
    height: 250px;
  }
}
/* Tablet */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .top-section-pict-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    margin-left: 240px;
    margin-top: 30px;
  }

  .yellow-bar-management-tittle {
    border-top: 3px solid #faa61a;
    width: 30px;
    position: relative;
    top: -10px;
  }

  .pict-list-management-wrapper {
    width: 500px;
    flex-shrink: 0;
    border-bottom-width: 3px;
    border-color: #ebebeb;
    display: flex;
    flex-direction: column;
  }

  .management-text-wrapper {
    margin-top: 30px;
  }

  .overlay-text-management-tittle {
    color: #000;

    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 370px;
  }

  .overlay-text-management-role {
    color: #000;

    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .overlay-text-management-desc {
    max-width: 455px;
    color: #000;

    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .management-image-wrapper {
    top: 20px;
    right: 35px;
    left: 0px;
    position: relative;
  }

  .management-image-wrapper img {
    width: 300px;
    height: 300px;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1440px) {
  .managament-layout-main {
    display: flex;
    flex-direction: column;
    margin-left: 250px;
    margin-right: 250px;
  }

  .top-section-pict-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    margin-top: 60px;
  }

  .pict-list-management-wrapper {
    width: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
  }

  .pict-list-management-wrapper-pengurus {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    margin-bottom: 70px;
  }

  .management-text-wrapper {
    margin-left: 90px;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    margin-top: 20px;
  }

  .overlay-text-management-tittle {
    color: #000;
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .overlay-text-management-role {
    color: #000;
    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .overlay-text-management-desc {
    max-width: 766px;
    max-height: 553px;
    color: #000;
    font-size: 20px;
    line-height: 1.4;
    text-align: left;
  }

  .management-image-wrapper {
    top: 22px;
    right: 35px;
    left: 10px;
    position: relative;
  }

  .management-image-wrapper img {
    width: 420px;
    height: 300px;
  }

  .gray-sepparator {
    border-top-width: 2px;
    width: 100%;
    color: #ebebeb;
    margin-top: 70px;
  }

  .special-pak-budi-gray-sepparator {
    border-top-width: 2px;
    width: 100%;
    color: #ebebeb;
    margin-top: 80px;
  }

  .gray-sepparator-pengurus {
    border-top-width: 2px;
    width: 100%;
    color: #ebebeb;
    margin-top: -10px;
  }
}

/* Untuk Desktop PC */
@media only screen and (min-width: 1920px) {
  .managament-layout-main {
    display: flex;
    flex-direction: row;
    margin-left: 250px;
    margin-right: 250px;
  }
  .parent-container {
    margin-bottom: 55px;
  }
  .top-section-pict-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    margin-top: 60px;
  }

  .pict-list-management-wrapper {
    width: 1245px;
    height: 410px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
  }

  .pict-list-management-wrapper-pengurus {
    width: 1245px;
    height: 410px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    margin-bottom: 70px;
  }

  .management-text-wrapper {
    margin-left: 90px;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    margin-top: 20px;
  }

  .overlay-text-management-tittle {
    color: #000;
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: "Hedvig Letters Serif, serif";
  }

  .overlay-text-management-role {
    color: #000;
    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .overlay-text-management-desc {
    max-width: 766px;
    max-height: 553px;
    color: #000;
    font-size: 20px;
    line-height: 1.4;
    text-align: left;
    font-family: "Maven Pro";
  }

  .management-image-wrapper {
    top: 22px;
    right: 35px;
    left: 10px;
    position: relative;
  }

  .management-image-wrapper img {
    width: 357.59px;
    height: 357.59px;
  }

  .gray-sepparator {
    border-top-width: 2px;
    width: 100%;
    color: #ebebeb;
    margin-top: 70px;
  }

  .special-pak-budi-gray-sepparator {
    border-top-width: 2px;
    width: 100%;
    color: #ebebeb;
    margin-top: 80px;
  }

  .gray-sepparator-pengurus {
    border-top-width: 2px;
    width: 100%;
    color: #ebebeb;
    margin-top: -10px;
  }
}

/* End Of Style Component Top Section Pict*/
