/* Untuk Mobile */
@media only screen and (max-width: 600px) {
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Warna latar belakang yang diredupkan */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Modal Window */
  .modal {
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); /* Efek bayangan */
    width: 340px;
    height: 500px;
    margin-top: 100px;
    overflow-y: auto;
  }

  /* Close Button */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #333;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Warna latar belakang yang diredupkan */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Modal Window */
  .modal {
    background-image: linear-gradient(to right, #7b82c3, #0d3cf9);
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); /* Efek bayangan */
    width: 740px;
    height: 828px;
    margin-top: 50px;
    /* overflow-y: auto; Jika konten lebih panjang dari modal, gunakan scrollbar */
  }

  /* Close Button */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #333;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1920px) {
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Warna latar belakang yang diredupkan */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Modal Window */
  .modal {
    background-image: linear-gradient(to right, #7b82c3, #0d3cf9);
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); /* Efek bayangan */
    width: 1250px;
    height: 628px;
    margin-top: 90px;
    /* overflow-y: auto; Jika konten lebih panjang dari modal, gunakan scrollbar */
  }

  /* Close Button */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #333;
  }
}

@media only screen and (min-width: 1920px) {
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Warna latar belakang yang diredupkan */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Modal Window */
  .modal {
    background-image: linear-gradient(to right, #7b82c3, #0d3cf9);
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); /* Efek bayangan */
    width: 1250px;
    height: 628px;
    margin-top: 90px;
    /* overflow-y: auto; Jika konten lebih panjang dari modal, gunakan scrollbar */
  }

  /* Close Button */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #333;
  }
}
