/* mobile */
@media only screen and (max-width: 600px) {
  .tittle-management-wrapper {
    background: #ebebeb;
  }

  .page-tittle {
    margin-top: 40px;
  }

  .tittle-container {
    background: #ebebeb;
  }

  .tittle-container p {
    color: #000;
    font-size: 36px;
    line-height: normal;
    margin-left: 125px;
    margin-top: 61px;
  }

  .deskripsi-management-container p {
    text-align: center;
    font-weight: 400;
    line-height: normal;
    color: #000;
  }

  .image-list-container {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Optional: adds space between items */
    margin-left: 30px;
    margin-right: 20px;
  }

  .image-list-wrapper {
    border: 2px solid #ebebeb;
    display: flex;
    flex-direction: column;
    width: 315px;
    height: 520px;
    margin-bottom: 57px;
    border-radius: 20px;
  }

  .image-list-wrapper img {
    width: 315px;
    min-height: 110px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .overlay-text-tittle {
    font-size: 18px;
    line-height: 20px;
    width: 270px;
    min-height: 50px;
    margin-top: 27px;
    margin-left: 27px;
    font-family: "Hedvig Letters Serif";
  }

  .overlay-text-desc {
    font-size: 14px;
    color: #000;
    font-weight: normal;
    line-height: 1.5;
    width: 270px;
    margin-left: 27px;
    margin-top: -10px;
  }

  .link-layout {
    background-color: #000;
    width: 170px;
    height: 40px;
    border-radius: 7px;
    padding: 12px 14.95px 12px 16px;
    margin-left: 27px;
    margin-right: 40px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  .link-layout:hover {
    background-color: #48d1cc;
  }
  .link-layout:active {
    transform: translateY(1px);
  }
  .link-layout p {
    font-size: 11.5px;
    color: #fff;
    font-family: "Maven Pro";
    letter-spacing: 2px;
  }
}

/* Tablet */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .tittle-management-wrapper {
    background: #ebebeb;
  }

  .page-tittle {
    margin-top: 80px;
  }

  .tittle-container {
    background: #ebebeb;
  }

  .tittle-container p {
    color: #000;
    font-size: 36px;
    line-height: normal;
    margin-left: 125px;
    margin-top: 61px;
  }

  .deskripsi-management-container p {
    text-align: center;
    font-weight: 400;
    line-height: normal;
    color: #000;
  }

  .image-list-container {
    display: flex;
    flex-direction: column;

    gap: 30px; /* Optional: adds space between items */
    margin-left: 60px;
    margin-right: 60px;
  }

  .image-list-wrapper {
    border: 2px solid #ebebeb;
    display: flex;
    flex-direction: column;
    width: 270px;
    height: 690px;
    margin-bottom: 57px;
    border-radius: 20px;
  }

  .image-list-wrapper img {
    width: 270px;
    min-height: 210px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .overlay-text-tittle {
    font-size: 16px;
    line-height: 20px;
    width: 188px;
    min-height: 50px;
    margin-top: 41px;
    margin-left: 37px;
    font-family: "Hedvig Letters Serif";
  }

  .overlay-text-desc {
    font-size: 14px;
    color: #000;
    font-weight: normal;
    margin-top: 2px;
    line-height: 1.5;
    width: 190px;
    min-height: 225px;
    margin-left: 37px;
  }

  .link-layout {
    background-color: #000;
    width: 190px;
    height: 40px;
    border-radius: 7px;
    padding: 12px 14.95px 12px 16px;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 35px;
    display: flex;
    justify-content: center;
  }

  .link-layout:hover {
    background-color: #48d1cc;
  }
  .link-layout:active {
    transform: translateY(1px);
  }
  .link-layout p {
    font-size: 12px;
    color: #fff;
    font-family: "Maven Pro";
    letter-spacing: 2px;
  }
}

/* Laptop */
/* @media only screen and (min-width: 1366px) and (max-width: 1920px) {
  .tittle-management-wrapper {
    background: #ebebeb;
  }

  .horizontal-line {
    border-top: 2px solid #ebebeb;
    width: 100%;
  }

  .tittle-container {
    margin-bottom: -28px;
    background: #ebebeb;
  }

  .tittle-container p {
    color: #000;
    font-size: 36px;

    line-height: normal;
    margin-left: 125px;
    margin-top: 61px;
  }

  .deskripsi-management-container p {
    text-align: center;
    font-weight: 400;
    line-height: normal;
    color: #000;
  }

  .image-list-container {
    display: grid;
    grid-template-columns: repeat(2, 0fr);
    gap: 30px; 
    padding: 16px;
    justify-content: center;
    align-items: center;
  }

  .image-list-wrapper {
    border: 2px solid #ebebeb;
    display: flex;
    flex-direction: column;
    padding: 19px;
    width: 400px;
    height: 447px;
    margin-bottom: 57px;
  }

  .image-list-wrapper img {
    width: 380px;
    height: 200px;
  }

  .overlay-text-tittle {
    font-size: 17px;
    font-weight: bold;
    line-height: 22px;
    width: 300px;
    margin-top: 12px;
  }

  .yellow-bar-project {
    border-top: 5px solid #faa61a;
    width: 55px;
    position: relative;
    top: -5px;
  }

  .overlay-text-desc {
    font-size: 14px;
    color: #000;
    font-weight: normal;
    margin-top: 20px;
  }

  .link-layout {
    background-color: turquoise;
    width: 133px;
    height: 26px;
    border-radius: 15px;
    padding: 5px 14.95px 6px 16px;
    display: flex;
    justify-content: center;
  }

  .link-layout:hover {
    background-color: #48d1cc;
  }
  .link-layout:active {
    transform: translateY(1px);
  }
  .link-layout p {
    font-size: 14px;
    color: #fff;
  }
} */

/* Desktop PC */
@media only screen and (min-width: 1920px) {
  .tittle-management-wrapper {
    background: #ebebeb;
  }

  .page-tittle {
    margin-top: 80px;
  }

  .tittle-container {
    background: #ebebeb;
  }

  .tittle-container p {
    color: #000;
    font-size: 36px;

    line-height: normal;
    margin-left: 125px;
    margin-top: 61px;
  }

  .deskripsi-management-container p {
    text-align: center;
    font-weight: 400;
    line-height: normal;
    color: #000;
  }

  .image-list-container {
    display: grid;
    grid-template-columns: repeat(2, 0fr);
    gap: 30px; /* Optional: adds space between items */
    margin-left: 270px;
    margin-right: 270px;
  }

  .image-list-wrapper {
    border: 2px solid #ebebeb;
    display: flex;
    flex-direction: column;
    width: 670px;
    height: 990px;
    margin-bottom: 57px;
    border-radius: 20px;
  }

  .image-list-wrapper img {
    width: 670px;
    min-height: 410px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .overlay-text-tittle {
    font-size: 32px;
    line-height: 40px;
    width: 488px;
    min-height: 50px;
    margin-top: 41px;
    margin-left: 37px;
    font-family: "Hedvig Letters Serif";
  }

  .overlay-text-desc {
    font-size: 22px;
    color: #000;
    font-weight: normal;
    margin-top: 2px;
    line-height: 1.5;
    width: 515px;
    min-height: 225px;
    margin-left: 37px;
  }

  .link-layout {
    background-color: #000;
    width: 249px;
    height: 40px;
    border-radius: 7px;
    padding: 9px 14.95px 9px 16px;
    margin-left: 170px;
    margin-right: 170px;
    margin-top: 35px;
    display: flex;
    justify-content: center;
  }

  .link-layout:hover {
    background-color: #48d1cc;
  }
  .link-layout:active {
    transform: translateY(1px);
  }
  .link-layout p {
    font-size: 19px;
    color: #fff;
    font-family: "Maven Pro";
    letter-spacing: 2px;
  }
}
