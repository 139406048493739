/* Mobile Style */
@media only screen and (max-width: 600px) {
  .page-tittle-event {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .page-tittle-event p {
    color: #000;
    
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .gallery {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 8px;
  }
  .date-style {
    color: var(--typo-text-secondary, rgba(0, 0, 0, 0.45));
    
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .gallery-item {
    border-radius: 8px;
    padding: 16px;
    background: #fff;
    width: 300px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
  }

  .gallery-item img {
    height: 193px;
  }

  .gallery-text-item {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 14px;
  }

  .gallery-text-item-headline {
    color: #000;
    
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.34px;
  }

  .gallery-text-item-deskripsi {
    color: var(--typo-text, var(--Colors-Neutral-Text-colorTextSecondary, rgba(0, 0, 0, 0.65)));
    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.084px;
    text-align: left;
  }

  .gallery-text-item-maker {
    color: var(--typo-text-secondary, rgba(0, 0, 0, 0.45));
    
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .maker-layout {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  .maker-layout img {
    width: 16px;
    height: 16px;
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 41px;
    margin-bottom: 160px;
  }

  .pagination button {
    margin: 0 4px;
    padding: 8px;
    cursor: pointer;
    border: 1px solid #000;
    padding: 4px 9px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }

  .pagination button.active {
    background-color: #09588d;
    color: #fff;
    border: 1px solid #000;
    padding: 4px 9px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-logo-layout {
    position: relative;
    top: -50px;
    left: 110px;
  }
  .image-logo-layout img {
    width: 76px;
    height: 76px;
  }
  .separator-border-logo1 {
    border-bottom-width: 3px;
    border-color: #ddd;
    top: -27px;
    left: -110px;
    position: relative;
    width: 109px;
  }
  .separator-border-logo2 {
    border-bottom-width: 3px;
    border-color: #ddd;
    top: -29px;
    left: 77px;
    width: 115px;
    position: relative;
  }
  .kerjasama-yayasan-layout {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
  }
  .kerjasama-yayasan-box {
    border-left-width: 3px;
    border-right-width: 3px;
    border-bottom-width: 3px;
    border-color: #ddd;
    width: 305px;
  }
  .kerjasama-yayasan-content {
    margin-top: 90px;
  }
  .kerjasama-yayasan-content ol {
    width: 270px;
    margin-left: 17px;
    margin-top: 16px;
  }

  .kerjasama-yayasan-content li {
    margin-bottom: 30px;
  }

  .kerjasama-yayasan-content h1 {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  }
  .separator-border {
    border-bottom-width: 3px;
    border-color: #ddd;
    margin-bottom: 30px;
  }
}
/* Table Style */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .page-tittle-event {
    display: flex;
    justify-content: center;
    margin-right: 1.8%;
  }
  .page-tittle-event p {
    color: #000;
    
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .gallery {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 8px;
  }
  .date-style {
    color: var(--typo-text-secondary, rgba(0, 0, 0, 0.45));
    
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .gallery-item {
    border-radius: 8px;
    padding: 16px;
    background: #fff;
    width: 300px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
    min-height: 400px;
    display: flex; /* Menjadikan konten dalam item flex */
    flex-direction: column;
  }

  .gallery-item img {
    height: 193px;
    width: 100%;
    object-fit: cover;
  }

  .gallery-text-item {
    display: flex;
    flex-direction: column;
    gap: 0px;
    flex-grow: 1;
  }

  .gallery-text-item-headline {
    color: #000;
    
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.34px;
    width: 270px;
    margin-bottom: 8px;
  }

  .gallery-text-item-deskripsi {
    color: var(--typo-text, var(--Colors-Neutral-Text-colorTextSecondary, rgba(0, 0, 0, 0.65)));
    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.084px;
    text-align: left;
    flex-grow: 1;
  }

  .gallery-text-item-maker {
    color: var(--typo-text-secondary, rgba(0, 0, 0, 0.45));
    
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .maker-layout {
    display: flex;
    flex-direction: row;
  }

  .maker-layout img {
    width: 16px;
    height: 16px;
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 41px;
    margin-bottom: 160px;
  }

  .pagination button {
    margin: 0 4px;
    padding: 8px;
    cursor: pointer;
    border: 1px solid #000;
    padding: 4px 9px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }

  .pagination button.active {
    background-color: #09588d;
    color: #fff;
    border: 1px solid #000;
    padding: 4px 9px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-logo-layout {
    position: relative;
    top: -150px;
    left: 34%;
  }
  .separator-border-logo1 {
    border-bottom-width: 3px;
    border-color: #ddd;
    top: -84px;
    left: -240px;
    position: relative;
    width: 240px;
  }
  .separator-border-logo2 {
    border-bottom-width: 3px;
    border-color: #ddd;
    top: -85px;
    left: 240px;
    width: 221px;
    position: relative;
  }
  .kerjasama-yayasan-layout {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
  }
  .kerjasama-yayasan-box {
    border-left-width: 3px;
    border-right-width: 3px;
    border-bottom-width: 3px;
    border-color: #ddd;
    width: 700px;
  }
  .kerjasama-yayasan-content {
    margin-top: -110px;
  }
  .kerjasama-yayasan-content ol {
    width: 580px;
    margin-left: 65px;
  }

  .kerjasama-yayasan-content li {
    margin-bottom: 30px;
  }

  .kerjasama-yayasan-content h1 {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  .separator-border {
    border-bottom-width: 3px;
    border-color: #ddd;
    margin-bottom: 30px;
  }
}

/* Laptop Style */
@media only screen and (min-width: 1366px) and (max-width: 1920px) {
  .page-tittle-event {
    display: flex;
    justify-content: center;
    margin-right: 1.8%;
  }
  .page-tittle-event p {
    color: #000;
    
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .gallery {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 8px;
  }
  .date-style {
    color: var(--typo-text-secondary, rgba(0, 0, 0, 0.45));
    
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .gallery-item {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    padding: 16px;
    background: #fff;
    width: 300px;
    min-height: 424px; /* Atur tinggi minimum item */
    display: flex; /* Menjadikan konten dalam item flex */
    flex-direction: column;
  }

  .gallery-item img {
    height: 193px;
    width: 100%; /* Agar gambar mengisi lebar konten */
    object-fit: cover;
  }

  .gallery-text-item {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 14px;
  }

  .gallery-text-item-headline {
    color: #000;
    
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.34px;
    margin-bottom: 8px;
  }

  .gallery-text-item-deskripsi {
    color: var(--typo-text, var(--Colors-Neutral-Text-colorTextSecondary, rgba(0, 0, 0, 0.65)));
    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.084px;
    text-align: left;
    flex-grow: 1;
  }

  .gallery-text-item-maker {
    color: var(--typo-text-secondary, rgba(0, 0, 0, 0.45));
    
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .maker-layout {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  .maker-layout img {
    width: 16px;
    height: 16px;
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 41px;
    margin-bottom: 160px;
  }

  .pagination button {
    margin: 0 4px;
    padding: 8px;
    cursor: pointer;
    border: 1px solid #000;
    padding: 4px 9px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }

  .pagination button.active {
    background-color: #09588d;
    color: #fff;
    border: 1px solid #000;
    padding: 4px 9px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-logo-layout {
    position: relative;
    top: -150px;
    left: 40%;
  }
  .separator-border-logo1 {
    border-bottom-width: 3px;
    border-color: #ddd;
    top: -84px;
    left: -504px;
    position: relative;
    width: 490px;
  }
  .separator-border-logo2 {
    border-bottom-width: 3px;
    border-color: #ddd;
    top: -85px;
    left: 240px;
    width: 514px;
    position: relative;
  }
  .kerjasama-yayasan-layout {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
  }
  .kerjasama-yayasan-box {
    border-left-width: 3px;
    border-right-width: 3px;
    border-bottom-width: 3px;
    border-color: #ddd;
    width: 1260px;
  }
  .kerjasama-yayasan-content {
    margin-top: -110px;
  }
  .kerjasama-yayasan-content ol {
    width: 1080px;
    margin-left: 80px;
  }

  .kerjasama-yayasan-content li {
    margin-bottom: 30px;
  }

  .kerjasama-yayasan-content h1 {
    font-size: 34px;
    font-weight: bold;
    text-align: center;
  }
  .separator-border {
    border-bottom-width: 3px;
    border-color: #ddd;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 1920px) {
  .page-tittle-event {
    display: flex;
    justify-content: center;
    margin-right: 1.8%;
  }
  .page-tittle-event p {
    color: #000;
    
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
  }
  .gallery {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 8px;
  }
  .date-style {
    color: var(--typo-text-secondary, rgba(0, 0, 0, 0.45));
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    position: relative;
    top: 2px;
  }

  .gallery-item {
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
    min-width: 324px;
    max-height: 452px; /* Atur tinggi minimum item */
    display: flex; /* Menjadikan konten dalam item flex */
    flex-direction: column; /* Mengatur konten dalam item agar berada dalam satu kolom */
  }

  .gallery-item img {
    height: 193px;
    width: 292px;
    object-fit: cover;
  }

  .gallery-text-item {
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex-grow: 1;
    align-self: stretch;
    margin-top: 14px;
  }

  .gallery-text-item-headline {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.34px;
    margin-bottom: 8px;
  }

  .gallery-text-item-deskripsi {
    color: var(--typo-text, var(--Colors-Neutral-Text-colorTextSecondary, rgba(0, 0, 0, 0.65)));
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.084px;
    text-align: left;
    flex-grow: 1;
  }

  .gallery-text-item-maker {
    color: var(--typo-text-secondary, rgba(0, 0, 0, 0.45));
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .maker-layout {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-top: 50px;
  }

  .maker-layout img {
    width: 16px;
    height: 16px;
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 41px;
    margin-bottom: 160px;
  }

  .pagination button {
    margin: 0 4px;
    padding: 8px;
    cursor: pointer;
    border: 1px solid #000;
    padding: 4px 9px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }

  .pagination button.active {
    background-color: #09588d;
    color: #fff;
    border: 1px solid #000;
    padding: 4px 9px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-logo-layout {
    position: relative;
    top: -150px;
    left: 40%;
  }
  .separator-border-logo1 {
    border-bottom-width: 3px;
    border-color: #ddd;
    top: -84px;
    left: -504px;
    position: relative;
    width: 490px;
  }
  .separator-border-logo2 {
    border-bottom-width: 3px;
    border-color: #ddd;
    top: -85px;
    left: 240px;
    width: 514px;
    position: relative;
  }
  .kerjasama-yayasan-layout {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
  }
  .kerjasama-yayasan-box {
    border-left-width: 3px;
    border-right-width: 3px;
    border-bottom-width: 3px;
    border-color: #ddd;
    width: 1260px;
  }
  .kerjasama-yayasan-content {
    margin-top: -110px;
  }
  .kerjasama-yayasan-content ol {
    width: 1080px;
    margin-left: 80px;
  }

  .kerjasama-yayasan-content li {
    margin-bottom: 30px;
  }

  .kerjasama-yayasan-content h1 {
    font-size: 34px;
    font-weight: bold;
    text-align: center;
  }
  .separator-border {
    border-bottom-width: 3px;
    border-color: #ddd;
    margin-bottom: 30px;
  }
}

.yellow-bar {
  border-top: 7px solid #faa61a;
  width: 80px;
  position: relative;
  top: -14px;
}
