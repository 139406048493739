/* Style Carousel Gallery */

/* Untuk Mobile */
@media only screen and (max-width: 600px) {
  .ant-carousel .slick-dots li {
    background-color: grey;
    border-radius: 50%;
  }

  .ant-carousel .slick-dots li.slick-active {
    background-color: #41a0ff;
    border-radius: 50%;
  }

  .gallery-project-detail-layout {
    width: 115px;
    height: 88px;
    box-sizing: border-box;
    margin-bottom: 50px;
    border-radius: 20px;
    align-self: stretch;
  }

  .gallery-container {
    margin-right: 35px;
    margin-left: 35px;
    width: 370px;
    margin-top: 35px;
    margin-bottom: 20px;
  }

  .gallery-tittle {
    margin-left: 35px;
    margin-top: 40px;
  }

  .gallery-tittle h2 {
    color: #000;

    font-size: 15.326px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
  }
}

/* Untuk Tablet */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .ant-carousel .slick-dots li {
    background-color: grey;
    border-radius: 50%;
  }

  .ant-carousel .slick-dots li.slick-active {
    background-color: #41a0ff;
    border-radius: 50%;
  }

  .gallery-project-detail-layout {
    width: 230px;
    height: 175px;
    box-sizing: border-box;
    margin-bottom: 50px;
    border-radius: 20px;
    align-self: stretch;
  }

  .gallery-container {
    margin-right: 35px;
    margin-left: 35px;
    width: 720px;
    margin-top: 35px;
    margin-bottom: 20px;
  }

  .gallery-tittle {
    margin-left: 35px;
    margin-top: 40px;
  }

  .gallery-tittle h2 {
    color: #000;

    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
  }
}

/* Untuk Laptop */
@media only screen and (min-width: 1366px) and (max-width: 1920px) {
  .ant-carousel .slick-dots li {
    background-color: grey;
    border-radius: 50%;
  }

  .ant-carousel .slick-dots li.slick-active {
    background-color: #41a0ff;
    border-radius: 50%;
  }

  .gallery-project-detail-layout {
    width: 370px;
    height: 280px;
    box-sizing: border-box;
    margin-bottom: 50px;
    border-radius: 20px;
    align-self: stretch;
  }

  .gallery-outer-container {
    display: flex;
    justify-content: center;
  }

  .gallery-container {
    margin-left: -10px;
    width: 1170px;
    margin-top: 35px;
    margin-bottom: 20px;
  }

  .gallery-tittle {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-right: 950px;
  }

  .gallery-tittle h2 {
    color: #000;

    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
  }
}
/* Untuk PC */
@media only screen and (min-width: 1920px) {
  .ant-carousel .slick-dots li {
    background-color: grey;
    border-radius: 50%;
  }

  .ant-carousel .slick-dots li.slick-active {
    background-color: #41a0ff;
    border-radius: 50%;
  }

  .gallery-project-detail-layout {
    width: 370px;
    height: 280px;
    box-sizing: border-box;
    margin-bottom: 50px;
    border-radius: 20px;
    align-self: stretch;
  }

  .gallery-outer-container {
    display: flex;
    justify-content: center;
  }

  .gallery-container {
    margin-left: 10px;
    width: 1170px;
    margin-top: 35px;
    margin-bottom: 20px;
  }

  .gallery-tittle {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    margin-right: 950px;
  }

  .gallery-tittle h2 {
    color: #000;

    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
  }
}

/* End Style Gallery Carousel */

/* Style Main Component Project Detail */

/* Untuk Mobile */
@media only screen and (max-width: 600px) {
  .project-detail-main-container h1 {
    color: #000;

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    display: flex;
    justify-content: center;
    text-align: center;
  }

  .image-detail-layout {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-detail-layout img {
    width: 306.034px;
    height: 170px;
    border-radius: 20px;
    margin-top: 20px;
  }

  .desc-container-layout p {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.176px;
    text-align: justify;
  }

  .desc-container-layout {
    width: 306px;
  }

  .desc-main-container {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .detail-selengkapnya-layout {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: flex-start;
    align-items: center;
  }

  .detail-selengkapnya-text {
    color: #000;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    width: 306px;
  }

  .button-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .button-download-style {
    background-color: #09588d;
    display: flex;
    flex-direction: row;
    padding-top: 2px;
    padding-bottom: 2px;
    justify-content: center;
    align-items: center;
    width: 150px;
    border-radius: 12px;
    gap: 10px;
  }

  .button-image-style {
    width: 18px;
    height: 18px;
  }

  .button-text-style {
    color: #fff;

    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-top: 10px;
  }
}

/* Untuk Tablet */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .project-detail-main-container h1 {
    color: #000;

    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .image-detail-layout {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-detail-layout img {
    width: 900px;
    height: 290px;
    border-radius: 20px;
    margin-top: 20px;
  }

  .desc-container-layout p {
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.8px;
    text-align: justify;
  }

  .desc-container-layout {
    width: 500px;
  }

  .desc-main-container {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .detail-selengkapnya-layout {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: flex-start;
    align-items: center;
  }

  .detail-selengkapnya-text {
    color: #000;

    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    width: 500px;
  }

  .button-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .button-download-style {
    background-color: #09588d;
    display: flex;
    flex-direction: row;
    padding-top: 12px;
    padding-bottom: 12px;
    justify-content: center;
    align-items: center;
    width: 200px;
    border-radius: 12px;
    gap: 10px;
  }

  .button-image-style {
    width: 30px;
    height: 30px;
  }

  .button-text-style {
    color: #fff;

    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-top: 18px;
  }
}

/* Untuk Laptop */
@media only screen and (min-width: 1366px) and (max-width: 1440px) {
  .project-detail-main-container {
    width: auto;
    margin-top: 100px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .project-detail-main-container h1 {
    color: #000;
    font-size: 54px;
    font-family: "Hedvig Letters Serif";
    line-height: 64px;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 1300px;
  }

  .image-detail-layout {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-detail-layout img {
    width: 1100px;
    height: 620px;
    border-radius: 20px;
    margin-top: 20px;
  }

  .desc-container-layout p {
    font-size: 22px;

    line-height: 1.5;
    letter-spacing: -0.176px;
    text-align: left;
    padding-right: 5%;
    padding-left: 5%;
  }

  .desc-container-layout {
    width: 100%;
  }

  .desc-main-container {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  .detail-selengkapnya-layout {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }

  .detail-selengkapnya-text {
    color: #000;

    font-size: 22px;
    font-style: normal;
    font-weight: 500;
  }

  .button-download-style {
    background-color: #09588d;
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: center;
    align-items: center;
    width: 200px;
    border-radius: 12px;
    gap: 10px;
  }

  .button-image-style {
    width: 30px;
    height: 30px;
  }

  .button-text-style {
    color: #fff;

    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-top: 18px;
  }
}
/* Untuk Desktop */
@media only screen and (min-width: 1920px) {
  .project-detail-main-container {
    margin-top: 100px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .project-detail-main-container h1 {
    color: #000;
    font-size: 54px;
    font-family: "Hedvig Letters Serif";
    line-height: 64px;
    width: 1220px;
    text-align: center;
  }

  .image-detail-layout {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-detail-layout img {
    width: 1220px;
    height: 620px;
    border-radius: 11px;
    margin-top: 20px;
  }

  .desc-container-layout p {
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.176px;
    text-align: left;
    font-family: "Maven Pro";
    width: 1220px;
  }

  .desc-container-layout {
    width: 1490px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .desc-main-container {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .detail-selengkapnya-layout {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: flex-start;
    align-items: center;
  }

  .detail-selengkapnya-text {
    color: #000;
    font-family: "Maven Pro";
    font-size: 22px;
    letter-spacing: -0.176px;
    line-height: 32px;
  }

  .button-download-style {
    background-color: #09588d;
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: center;
    align-items: center;
    width: 200px;
    border-radius: 12px;
    gap: 10px;
  }

  .button-image-style {
    width: 30px;
    height: 30px;
  }

  .button-text-style {
    color: #fff;

    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-top: 18px;
  }
}
/* End Style Main Component Project Detail */
/* Style test pdf */
.a4-container {
  width: 210mm; /* Lebar A4 */
  height: 297mm; /* Tinggi A4 */
  border: 1px solid #000; /* Garis batas */
  box-sizing: border-box; /* Sertakan padding dan border dalam ukuran elemen */
  padding: 10mm; /* Padding (opsional, sesuaikan dengan kebutuhan) */
  margin: 20px auto; /* Margin untuk mengatur posisi tengah (opsional) */
  background-color: #fff; /* Warna latar belakang putih (opsional) */
}
/* style pdf */

.project-detail-main-container-pdf {
  width: auto;
}
.project-detail-main-container-pdf h2 {
  color: #000;
  font-size: 32px;
  font-family: "Hedvig Letters Serif";
  line-height: 64px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.desc-container-layout-pdf p {
  font-size: 16px;
  line-height: 32px;
  letter-spacing: -0.176px;
  text-align: left;
  font-family: "Maven Pro";
  width: 650px;
}

.desc-container-layout-pdf {
  width: 100%;
}

.desc-main-container-pdf {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-left: 30px;
  margin-right: 30px;
}

.image-detail-layout-pdf {
  display: flex;
  justify-content: center;
}

.image-detail-layout-pdf img {
  width: 500px;
  height: 300px;
}

.dana-detail-container {
  margin-top: 80px;
}

.dana-detail-content-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
}

.dana-detail-content-layout img {
  height: 100px;
  width: 100px;
}

/* style button download baru */
/* mobile */
@media only screen and (max-width: 600px) {
  .container-download {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .line-proyek1 {
    position: absolute;
    transform: translateY(-50%); /* Pusatkan garis di tengah secara vertikal */
    width: 100px; /* Lebar garis */
    height: 5px; /* Tinggi garis */
    right: 90px;
    background-color: #ebebeb; /* Warna garis */
  }
  .line-proyek2 {
    position: absolute;
    transform: translateY(-50%); /* Pusatkan garis di tengah secara vertikal */
    width: 100px; /* Lebar garis */
    height: 5px; /* Tinggi garis */
    left: 90px;
    background-color: #ebebeb; /* Warna garis */
  }

  .container-download img {
    position: relative;
    z-index: 1; /* Letakkan gambar di atas garis */
    width: 80px;
  }
}

/* tablet */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .container-download {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .line-proyek1 {
    position: absolute;
    transform: translateY(-50%); /* Pusatkan garis di tengah secara vertikal */
    width: 200px; /* Lebar garis */
    height: 5px; /* Tinggi garis */
    right: 90px;
    background-color: #ebebeb; /* Warna garis */
  }
  .line-proyek2 {
    position: absolute;
    transform: translateY(-50%); /* Pusatkan garis di tengah secara vertikal */
    width: 200px; /* Lebar garis */
    height: 5px; /* Tinggi garis */
    left: 90px;
    background-color: #ebebeb; /* Warna garis */
  }

  .container-download img {
    position: relative;
    z-index: 1; /* Letakkan gambar di atas garis */
    width: 80px;
  }
}

/* Laptop */
@media only screen and (min-width: 1366px) and (max-width: 1920px) {
  .container-download {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .line-proyek1 {
    position: absolute;
    transform: translateY(-50%); /* Pusatkan garis di tengah secara vertikal */
    width: 200px; /* Lebar garis */
    height: 5px; /* Tinggi garis */
    right: 160px;
    background-color: #ebebeb; /* Warna garis */
  }
  .line-proyek2 {
    position: absolute;
    transform: translateY(-50%); /* Pusatkan garis di tengah secara vertikal */
    width: 200px; /* Lebar garis */
    height: 5px; /* Tinggi garis */
    left: 160px;
    background-color: #ebebeb; /* Warna garis */
  }

  .container-download img {
    position: relative;
    z-index: 1; /* Letakkan gambar di atas garis */
    width: 150px;
  }
}

/* Desktop */

@media only screen and (min-width: 1920px) {
  .container-download {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .line-proyek1 {
    position: absolute;
    transform: translateY(-50%); /* Pusatkan garis di tengah secara vertikal */
    width: 200px; /* Lebar garis */
    height: 5px; /* Tinggi garis */
    right: 160px;
    background-color: #ebebeb; /* Warna garis */
  }
  .line-proyek2 {
    position: absolute;
    transform: translateY(-50%); /* Pusatkan garis di tengah secara vertikal */
    width: 200px; /* Lebar garis */
    height: 5px; /* Tinggi garis */
    left: 160px;
    background-color: #ebebeb; /* Warna garis */
  }

  .container-download img {
    position: relative;
    z-index: 1; /* Letakkan gambar di atas garis */
    width: 150px;
  }
}
